@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100&display=swap');

/* https://coolors.co/2b2d42-8d99ae-edf2f4-ef233c-d80032 */
/* https://coolors.co/f6f6f6-efefef-e8e8e8-262626-161616-660b0b-b60000-ce0101-e50101 */
/* E50101 - red color */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Work Sans', sans-serif !important;
  line-height: 1.6 !important;
  background-color: #f6f6f6;
  color: #f6f6f6;
}

.links {
  text-decoration: none !important;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 18px;
  color: #f6f6f6 !important;
}

.fluid-fix {
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
}

h5,
h4 {
  line-height: 1.6;
}

/* Background Video */

.hero-video-container {
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
}

#video {
  width: 100vw;
  height: 115vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  object-fit: cover;
  z-index: -4;
}

.overlay {
  width: 100vw;
  height: 115vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  object-fit: cover;
  z-index: -4;
  background-color: rgba(0, 0, 0, 0.7);
}

/* Header */

.navbar-main {
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: flex-start;
}

#logo-image {
  overflow: hidden;
  width: 100%;
  height: auto;
  margin: auto;
  max-width: 100px;
}

@media (max-width: 480px) {
  .navbar-main {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: flex-start;
  }
}

/* Hello Text */

@media (max-width: 480px) {
  #h1-media {
    font-size: 60px;
  }
}

.hello-col {
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 60px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
}
.hello-col h1 {
  font-size: 80px;
  line-height: 1;
}

.highlight {
  color: #e50101;
}

.hello-col h6 {
  font-size: 15px;
  opacity: 0.8;
  font-family: 'Work Sans', sans-serif !important;
  padding-left: 4.5px;
}

#button-col {
  display: flex;
  align-self: center;
  position: relative;
  top: 20vh;
  color: #f6f6f6;
  background: #ce0101;
  border: none;
  font-size: 20px;
  padding: 10px 10px;
}

/* Myself Section */

.myself-main-container {
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  background: #0a0a0a;
  height: 100vh;
  padding: 0px 20px;
}

.myself-col {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 40vh;
}

.skill-set {
  font-size: 10px;
  color: #f6f6f6;
  padding: 20px 10px;
}

.tech-image {
  max-width: 130px;
  min-height: 130px;
  max-width: 130px;
  margin: auto;
  width: 100%;
  height: auto;
  border: 8px solid #f6f6f6;
}

@media (max-width: 480px) {
  .myself-col h1 {
    font-size: 22px;
  }
  .myself-col h5 {
    font-size: 13px;
  }
  .skill h5 {
    font-size: 15px;
  }
  .tech-image {
    max-width: 70px;
    min-height: 70px;
    max-width: 70px;
    margin: auto;
    border: 3px solid #f6f6f6;
  }
}

/* Proud Section */

.proud-main-container {
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  background: #f6f6f6;
  height: 100vh;
  color: #0a0a0a;
}

.col-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.proud-wrapper {
  text-align: center;
  font-family: 'Work Sans', sans-serif !important;
  padding: 6rem 20px;
  width: 100%;
  margin: auto;
}

.proud-wrapper #work-title {
  font-size: 50px;
  padding-bottom: 50px;
}

.proud-wrapper h5 {
  padding-bottom: 50px;
}

#starting-tag {
  padding-top: 40px;
}

@media (max-width: 480px) {
  .col-wrapper h5 {
    font-size: 15px;
  }
  .col-wrapper h3 {
    font-size: 18px;
  }
}

/* Showcase */
.showcase-wrapper {
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 20px;
  background: #00a8e8;
  height: 100vh;
  padding: 0px 20px;
  color: #f6f6f6;
}

.mockimage {
  width: 100%;
  height: auto;
  max-width: 550px;
  max-height: 470px;
}

.mock-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 80vh;
}

.mock-text-wrapper h1,
h5 {
  padding: 10px 0px;
}

.mock-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  height: 80vh;
}

@media (max-width: 988px) {
  .mock-text-wrapper h5 {
    font-size: 15px;
  }
  .mock-text-wrapper {
    height: 60vh;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
  }
  .mock-image-wrapper {
    height: 20vh;
  }
}

/* Showcase Button  */

.showcase-button {
  margin-top: 50px;
  color: #f6f6f6;
  padding: 5px 50px;
  background: transparent;
  border: 2px solid #f6f6f6;
  border-radius: 5px;
  font-size: 22px;
}

@media (max-width: 988px) {
  .showcase-button {
    margin-top: 45px;
    font-size: 18px;
  }
}

/* feedback section  */

.feedback-wrapper {
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  background: #f6f6f6;
  height: 50vh;
  color: #0a0a0a;
  padding: 0px 20px;
}

.feedback-image {
  height: auto;
  max-width: 100px;
  border-radius: 50%;
  width: 100%;
  min-height: 100px;
  max-height: 100px;
}

.feedback-col-wrapper {
  display: flex;
  flex-direction: column;
  height: 50vh;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}

@media (max-width: 988px) {
  .feedback-col-wrapper h5 {
    font-size: 14px;
  }
  .feedback-col-wrapper h6 {
    font-size: 12px;
  }
  .feedback-image {
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
  }
}

/* Showcase- Second  */

.showcase-second-wrapper {
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 20px;
  background: #f18701;
  height: 100vh;
  color: #f6f6f6;
  padding: 0px 20px;
}

.secondmock-image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 80vh;
}

.secondmock-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  height: 80vh;
}

.secondmock-text-wrapper h1,
h5 {
  padding: 10px 0px;
}

@media (max-width: 988px) {
  .secondmock-text-wrapper h5 {
    font-size: 15px;
  }
  .secondmock-text-wrapper {
    height: 60vh;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
  }
  .secondmock-image-wrapper {
    height: 20vh;
  }
}

/* Showcase- Third  */

.showcase-third-wrapper {
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 20px;
  background: #3b3355;
  height: 100vh;
  color: #f6f6f6;
  padding: 0px 20px;
}

/* Showcase- forth  */

#google-badge {
  margin: auto;
  width: 100%;
  height: auto;
  max-width: 300px;
  max-height: 110px;
}

.showcase-forth-wrapper {
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 20px;
  background: #7cb518;
  height: 100vh;
  color: #f6f6f6;
  padding: 0px 20px;
}

@media (max-width: 988px) {
  #google-badge {
    margin: auto;
    width: 100%;
    height: auto;
    max-width: 220px;
    max-height: 110px;
  }
}

/* Showcase- fifth  */
.showcase-fifth-wrapper {
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 20px;
  background: #9e0059;
  height: 100vh;
  color: #f6f6f6;
  padding: 0px 20px;
}

/* Footer Section */
.footer-main-wrapper {
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  background: #0a0a0a;
  height: 70vh;
  color: #f6f6f6;
}

.footer-row {
  text-align: center;
  padding: 15px 10px;
  padding-top: 20px;
}

.footer-row h1 {
  font-size: 70px;
  padding-bottom: 20px;
  padding-top: 40px;
}

.footer-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 40px 5px;
}

.footer-links a {
  padding: 20px 10px;
}

.footer-icons {
  height: auto;
  max-width: 40px;
  margin: auto;
  width: 100%;
  max-height: 40px;
}

.footer-last-row {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 480px) {
  .footer-row h1 {
    font-size: 60px;
  }
  .footer-row h2 {
    font-size: 25px;
  }
  .footer-row p {
    font-size: 15px;
  }
  .footer-last-row h6 {
    font-size: 10px;
  }
}

/* Contact us  */

.modal-wrapper {
  color: #0a0a0a;
  padding: 50px;
}

.input-row-style {
  padding: 10px 10px;
}

.input-field {
  border-radius: 10px;
  padding: -10px -10px;
  box-shadow: 1px 1px 6px 0.1px rgba(0, 0, 0, 0.3);
}

.get-in-touch-row {
  padding: 15px 0px;
  margin: auto;
}

.btn-contactus {
  width: 300px;
  margin: auto;
  box-shadow: 1px 1px 6px 0.1px rgba(0, 0, 0, 0.3);
}

/* Loader Screen */
